<template>
  <vue-scroll class="register-page-player align-vertical">
    <div class="form-wrapper align-vertical-middle" v-loading="loading_table" :element-loading-text="text_loading"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <img class="image-logo" :src="pathImagesUrl + '/banner.png'" alt="logo" />
      <div v-if="show" class="form-box card-base card-shadow--extraLarge">
        <el-form :model="form" :rules="rules" size="small" ref="form" class="demo-form" status-icon v-loading="loading">
          <template v-if="!showForm && serial.verify_by_table_code === 1">
            <el-form-item prop="verify_by_table_code">
              <el-input ref="codeTable" size="medium" class="form-input" type="text" v-model="form.verify_by_table_code"
                :min="5" :max="10" autocomplete="off" placeholder="Ingresa el código de la tabla"></el-input>
            </el-form-item>
          </template>

          <transition name="fade">
            <div v-if="showForm">
              <el-form-item prop="type_id">
                <el-select v-model="form.type_id" placeholder="Selecciona tipo de documento">
                  <el-option v-for="item in types" :key="item.id" :label="item.name_type" :value="item.id">
                    <span style="float: left">
                      ({{ item.prefix }}) {{ item.name_type }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px"><i class="el-icon-postcard"></i></span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="nit">
                <el-input class="form-input" type="text" ref="nit" v-model="form.nit" autocomplete="off"
                  placeholder="Documento"></el-input>
              </el-form-item>
              <el-form-item prop="name_player">
                <el-input class="form-input" type="text" v-model="form.name_player" autocomplete="off"
                  placeholder="Nombre Completo"></el-input>
              </el-form-item>

              <el-form-item prop="country">
                <!-- <country @set-fields="setField($event)" :reset="resetField"></country> -->
                <country-select @set-fields="setField($event)" :reset="resetField"></country-select>
              </el-form-item>

              <!-- <el-form-item prop="name_company">
                <company-select @set-fields="setField($event)" :reset="resetField"></company-select>
              </el-form-item> -->

              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item prop="phone">
                    <!-- <el-input class="form-input" type="text" v-model.number="form.phone" autocomplete="off"
                      placeholder="Celular" ref="phone"></el-input> -->
                    <el-input class="form-input" type="text" ref="phone" v-model="form.phone" autocomplete="off"
                      placeholder="Número de Celular">
                      <template slot="prepend">{{phoneCode}}</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item prop="email">
                    <el-input class="form-input" type="email" name="email_1" ref="email" v-model="form.email"
                      autocomplete="off" placeholder="E-mail"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item prop="competitor">
                    <competitor @set-fields="setField($event)" :reset="resetField"></competitor>
                  </el-form-item>
                </el-col>
              </el-row> -->

              <!-- <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item prop="city_id">
                    <city @set-fields="setField($event)" :reset="resetField"></city>
                  </el-form-item>
                </el-col>
              </el-row> -->

              <el-form-item prop="quantity_tables" v-if="serial.select_number_tables">
                <label slot="label"><i class="el-icon-date"></i> Elije cuantas tablas quieres jugar.
                  Max({{ serial.quantity_tables }})</label>
                <el-input-number class="quantity_tables" v-model="form.quantity_tables" :min="1"
                  :max="serial.quantity_tables"></el-input-number>
              </el-form-item>


              <el-row>
                <el-col :span="24">
                  <el-form-item prop="polices" style="text-align: left">
                    <div class="terms-text" style="line-height: 15px">
                      <el-checkbox name="polices" v-model="form.polices">
                      </el-checkbox> &nbsp;
                      <!-- Política de tratameinto de datos. He leído, he sido informado
                  (a), autorizo el tratamiento de mis datos para los fines
                  previamente comunicados y acepto la política de privacidad y
                  de tratamiento de datos personales. -->

                      Al dar clic, declaro que he leído y acepto la
                      <el-link type="primary" target="_blank" :href="serial.terms">
                        Términos y condiciones
                      </el-link>
                      y consiento como usuario la Política de Tratamiento de Datos de los servicios de Recreación.
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </transition>
        </el-form>

        <div class="flex text-center center">
          <el-button v-if="showForm" plain type="primary" @click="save('form')"
            icon="el-icon-check">Registrarme</el-button>
          <el-button v-else plain type="success" @click="validateCodeTable()" icon="el-icon-position"
            :loading="loadingValidateTableCode">Validar mi código!</el-button>
        </div>
        <el-divider class="divider">O</el-divider>
        <div class="text-center login-box">
          <el-button type="primary" icon="el-icon-s-grid" @click="tableNumber(null)">Verifica si estas registrado!
          </el-button>
        </div>

        <div style="padding-top: 20px; font-size: 12px">
          Developed by
          <a href="https://aplicacionesexcel.com" target="_blank">AplexTM</a>
        </div>
      </div>
      <div v-if="hide">
        <div class="no-play">
          No hay tablas disponibles :(
          <span class="return-play">Ingresa a jugar</span>
          <div class="text-center login-box">
            <el-input class="form-input" type="text" v-model.number="nit_player" autocomplete="off"
              placeholder="Digita tu número de documento" ref="nit_player"></el-input>
            <el-button style="margin-top: 15px" type="primary" icon="el-icon-user" @click="getTableBingo">Buscar mi
              tabla</el-button>
          </div>
        </div>
      </div>
      <div v-if="maintenance">
        <div class="no-play">
          Estamos trabajando para ti!
          <span class="return-maintenance">Por favor regresa en unos minutos</span>
          <div>
            <i class="mdi mdi-settings mdi-spin mdi-48px"></i>
          </div>
          <!-- <img class="imaintenance-logo" src="@/assets/images/maintenance.gif" alt="mantenimiento" /> -->
        </div>
      </div>
      <div v-if="loading_game" class="loading-game">
        <i class="el-icon-loading"></i> Validando juegos disponibles...
      </div>
      <!-- <el-row :gutter="20">
        <el-col :span="24">
          <div>Apoya</div>
          <img
            class="image-row1"
            :src="pathImagesUrl + '/logo2.png'"
            alt="logo"
            style="object-fit: fill; width: 100px"
          />
          <img
            class="image-row1"
            :src="pathImagesUrl + '/logo3.png'"
            alt="logo"
            style="object-fit: fill; width: 100px"
          />
        </el-col>
      </el-row> -->
    </div>
  </vue-scroll>
</template>
<script>
import moment from 'moment-timezone'
import VueRecaptcha from "vue-recaptcha";
import { getAvailablePlay, store, getTable, getCode } from "@/api/player";
import { getTableCode } from "@/api/bingo";
import { setPlayer } from "@/utils/player";
import {
  validateEmail,
  resendEmail,
  validateNit,
  downloadTable,
} from "@/api/player";
import { getAll } from "@/api/typeDocument";
// CUSTOM FIELDS
import Company from "./customFields/Company.vue";
import CompanySelect from "./customFields/CompanySelect.vue";
import City from "./customFields/City.vue";
import Country from "./customFields/Country.vue";
import CountrySelect from "./customFields/CountrySelect.vue";
import Beneficiary from "./customFields/Beneficiary.vue";
import Zones from "./customFields/Zones.vue";
import Competitor from "./customFields/Competitor.vue";
export default {
  name: "Player",
  data() {
    var mailformat =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var phoneFormat = /^\+?(\d{1,3})?(\d{7})$/;

    var validateTableCode = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("Campo obligatorio"));
      } else if (value.length < 5) {
        callback(new Error("Caracteres minimos 5"));
      } else if (value.length > 10) {
        callback(new Error("Caracteres maximos 10"));
      } else {
        callback();
      }
    };
    var validate_nit = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("Campo obligatorio"));
      } else if (value.length < 4) {
        callback(new Error("Caracteres minimos 5"));
      } else if (/\D/.test(value)) {
        callback(new Error("Ingresa solo caracteres numéricos"));
        // callback();
      } else {
        // if (this.send) {
        //   validateNit({ nit: value }).then(({ data }) => {
        //     if (!data.valid) {
        //       callback(new Error(data.message));
        //     }
        //     callback();
        //   });
        // } else {
        callback();
        // }
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("Campo obligatorio"));
      } else if (value.length < 7) {
        callback(new Error("Caracteres minimos 7"));
      } else if (!phoneFormat.test(value)) {
        callback(new Error('No es un número teléfonico válido'));
      } else {
        callback();
      }
    };
    var validate_email = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("Campo obligatorio"));
      } else if (!mailformat.test(value)) {
        callback(new Error("No es un e-mail valido"));
      } else {
        // if (this.send) {
        //   validateEmail({ email: value }).then(({ data }) => {
        //     if (!data.valid) {
        //       callback(new Error(data.message));
        //     }
        //     callback();
        //   });
        // } else {
        callback();
        // }
      }
    };
    return {
      url_social: process.env.VUE_APP_ROOT + "images/facebook.png",
      pathImagesUrl: process.env.VUE_APP_ROOT + "images/" + process.env.VUE_APP_FOLDER_IMAGES,
      dynamicTags: [],
      loadingValidateTableCode: false,
      inputVisible: false,
      inputValue: "",
      loading: false,
      loading_table: false,
      loading_game: true,
      maintenance: false,
      showPhoneFamily: false,
      resetField: false,
      show: false,
      hide: false,
      table_bingo: "",
      nit_player: "",
      text_loading: "Asignando tabla...",
      options: [],
      city: {},
      cities: [],
      list: [],
      serial: {},
      types: [],
      send: false,
      phoneCode: '',
      form: {
        type_id: 1,
        nit: "",
        name_player: "",
        email: "",
        phone: "",
        city_id: "",
        polices: [],
        terms: [],
        captcha: "",
        name_company: "",
        zone: "",
        create_table: false, // si se asigan tabla automaticamente
        game_date: "",
        guardian: "",
        nit_guardian: "",
        children_name: "",
        children_age: "",
        airport: "",
        quantity_tables: 1,
        follower: false,
        phone_family_option: false,
        phone_family: "",
        country: "",
        beneficiary1: "",
        beneficiary2: "",
        beneficiary3: "",
        // FIELDS TQ
        competitor: "",
        competitor_phone: "",
        // code table
        verify_by_table_code: ""
      },
      rules: {
        type_id: [
          {
            required: true,
            message: "Selecciona un tipo de documento",
            trigger: ["change"],
          },
        ],
        nit: [{ validator: validate_nit }],
        name_player: [
          {
            required: true,
            message: "Por favor ingrese su nombre completo",
          },
        ],
        phone: [{ validator: validatePhone }],
        // phone: [
        //   {
        //     required: true,
        //     message: "Ingrese su teléfono",
        //   },
        //   { type: "number", message: "Número invalido" },
        // ],
        city_id: [
          {
            required: true,
            message: "Seleccione la ciudad",
          },
        ],
        country: [
          {
            required: true,
            message: "Seleccione el país",
          },
        ],
        email: [{ validator: validate_email }],
        polices: [
          {
            required: true,
            message: "Por favor acepte las políticas",
            trigger: ["change"],
          },
        ],
        terms: [
          {
            required: true,
            message: "Por favor acepte la política de tratamiento de datos",
            trigger: ["change"],
          },
        ],
        captcha: [
          {
            required: true,
            message: "Valida el captcha por favor",
          },
        ],
        name_company: [
          {
            required: true,
            message: "Por favor seleccione",
          },
        ],
        zone: [
          {
            required: true,
            message: "Por favor seleccione la zona",
          },
        ],
        game_date: [
          {
            required: true,
            message: "Por favor selecciona una fecha de juego",
            trigger: ["change"],
          },
        ],
        guardian: [
          {
            required: true,
            message: "Por favor ingrese el nombre del acudiente",
          },
        ],
        nit_guardian: [
          {
            required: true,
            message: "Por favor ingrese el documento del acudiente",
          },
        ],
        children_name: [
          {
            required: true,
            message: "Por favor ingrese el nombre del niño(a)",
          },
        ],
        children_age: [
          {
            required: true,
            message: "Por favor ingrese la edad nombre del niño(a)",
          },
        ],
        airport: [
          {
            required: true,
            message: "Ingrese el aeropuerto",
          },
        ],
        competitor: [
          {
            required: true,
            message: "Campos obligatorio",
          },
        ],
        competitor_phone: [
          {
            required: true,
            message: "Campos obligatorio",
          },
        ],
        phone_family: [
          {
            required: true,
            message: "Por favor ingrese el teléfono del familiar",
          },
        ],
        verify_by_table_code: [{ validator: validateTableCode }],
      },
      gameDates: [
        // { value: "2021-05-02", label: "2021-05-02" },
        // { value: "2021-05-16", label: "2021-05-16" },
        { value: "2021-05-23", label: "2021-05-23" },
      ],
      showForm: false,
    };
  },
  beforeMount() {
    // validar si hay juego
    this.validateAvaliablePlay();
  },
  mounted() {
    this.getTypes();
    // this.types = [
    //   {
    //     "id": 1,
    //     "name_type": "Cédula de Identidad",
    //     "prefix": "CI"
    //   },
    //   {
    //     "id": 2,
    //     "name_type": "Cédula de extranjería",
    //     "prefix": "CE"
    //   },
    //   {
    //     "id": 3,
    //     "name_type": "Pasaporte",
    //     "prefix": "P"
    //   },
    //   {
    //     "id": 4,
    //     "name_type": "Permiso Especial de Permanencia",
    //     "prefix": "PEP"
    //   },
    //   {
    //     "id": 5,
    //     "name_type": "Registro Federal de Contribuyentes",
    //     "prefix": "RFC",
    //     "country": "México"
    //   },
    //   {
    //     "id": 6,
    //     "name_type": "Clave Única de Registro de Población",
    //     "prefix": "CURP",
    //     "country": "México"
    //   },
    //   {
    //     "id": 7,
    //     "name_type": "Documento Nacional de Identidad",
    //     "prefix": "DNI",
    //     "country": "Perú"
    //   },
    //   {
    //     "id": 8,
    //     "name_type": "Documento Único de Identidad",
    //     "prefix": "DUI",
    //     "country": "El Salvador"
    //   }
    // ]
  },
  methods: {
    validateCodeTable() {
      let me = this
      me.loadingValidateTableCode = true
      if (me.form.verify_by_table_code !== "") {
        getTableCode({ code: me.form.verify_by_table_code })
          .then(({ data }) => {
            console.log('resp:', data);
            if (data.code === 200) {
              if (!data.data) {
                me.$message.warning(data.message);
              } else {
                me.$message.success("Tu código ha sido validado! Por favor, completa tu registro");
                me.showForm = true
              }
            } else {
              me.$message.warning(data.message);
            }
            me.loadingValidateTableCode = false
          })
          .catch(function (error) {
            me.loadingValidateTableCode = false
            console.log("Err: ", error);
          });
      } else {
        me.loadingValidateTableCode = false
        me.$message.warning("Debes ingresar un código de tabla!");
        me.$nextTick(() => me.$refs['codeTable'].focus());
      }
    },
    setField(data) {
      if (data.field == "name_company") {
        this.form.name_company = data.value;
      }
      if (data.field == 'name_zone') {
        this.form.zone = data.value;
      }
      if (data.field == "city_id") {
        this.form.city_id = data.value;
      }
      if (data.field == "country") {
        this.form.country = data.value.description;
        this.phoneCode = data.value.code;
      }
      if (data.field == "beneficiary1") {
        this.form.beneficiary1 = data.value;
      }
      if (data.field == "beneficiary2") {
        this.form.beneficiary2 = data.value;
      }
      if (data.field == "beneficiary3") {
        this.form.beneficiary3 = data.value;
      }
      if (data.field == "competitor1") {
        this.form.competitor = data.value;
      }
      if (data.field == "competitor2") {
        this.form.competitor_phone = data.value;
      }
    },
    changuePhoneFamily(val) {
      this.showPhoneFamily = val;
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let me = this;
      let inputValue = this.inputValue;
      if (inputValue) {
        me.loading_table = true;
        me.text_loading = "Validando Código...";
        if (me.dynamicTags.includes(inputValue)) {
          me.$message.warning("Ya has ingresado este código");
          me.loading_table = false;
        } else {
          getCode(inputValue)
            .then(function (response) {
              if (response.data !== "") {
                me.dynamicTags.push(inputValue);
                me.$message.success("Código ingresado exitosamente!");
              } else {
                me.$message.error(
                  "Error: El código ingresado no existe o ya ha sido usado!"
                );
              }
              me.loading_table = false;
            })
            .catch(function (error) {
              console.log(error);
              me.loading_table = false;
              me.$message.error("Error! " + error);
            });
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
      me.text_loading = "Asignando tabla...";
    },
    getTypes() {
      let me = this;
      getAll()
        .then(function (response) {
          me.types = response.data;
        })
        .catch(function (error) {
          console.log(error);
          toastr.warning("Error: -" + error);
        });
    },
    gameDateFormat(date) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var fecha = new Date(date + "T00:00:00");
      return fecha.toLocaleDateString("es-ES", options);
    },
    validate(response) {
      if (response) {
        this.form.captcha = response;
      }
    },
    resendEmail() {
      // jhonnyalejo2212@gmail.com
      let me = this;
      this.$prompt(
        "Por favor ingrese su email",
        "Reenviar Email de confirmacón",
        {
          closeOnClickModal: false,
          confirmButtonText: "Enviar Email",
          cancelButtonText: "Cancelar",
          type: "danger",
          inputType: "text",
          inputPattern:
            /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputPlaceholder: "mi-email@ejemplo.com",
          inputErrorMessage: "Ingresa un email valido",
          inputValidator: function (val) {
            if (val === null) {
              return false;
            } else {
              return true;
            }
          },
        }
      ).then(({ value }) => {
        this.loading_table = true;
        this.text_loading = "Validando...";
        validateEmail({ email: value }).then(({ data }) => {
          if (!data.valid) {
            this.resendEmailConfirmation(value);
          } else {
            this.loading_table = false;
            this.text_loading = "Asignando tabla...";
            this.$swal.fire({
              icon: "warning",
              title: "Atención!",
              html:
                "El email <strong>" + value + "</strong> NO esta registrado",
              showConfirmButton: true,
            });
            console.log(data);
          }
        });
      });
    },
    resendEmailConfirmation(email) {
      resendEmail(email)
        .then(({ data }) => {
          this.loading_table = false;
          this.text_loading = "Asignando tabla...";
          if (data.confirmed) {
            this.$swal.fire({
              icon: "warning",
              title: "Atencíon!",
              html: "Tu ya has validado exitosamente el registro, revisa en tu correo la fecha, hora y numero de tabla para jugar!",
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "success",
              title: "Email enviado!",
              html:
                "Por favor confirma tu registro desde el email enviado a <strong>" +
                email +
                "</strong> haciendo click en el vínculo, verificar email.<br>" +
                "<strong style='color:#ec205f'>Por favor verifica también en tu bandeja de no deseados (spam)</strong>",
              showConfirmButton: true,
            });
          }
        })
        .catch(function (error) {
          this.loading_table = false;
          this.text_loading = "Asignando tabla...";
          this.$message.error("Error.");
          console.log(error);
        });
    },
    save(formName) {
      this.loading = true;
      this.send = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.dynamicTags.length > 0) {
          // this.form.codes = this.dynamicTags;
          this.saveForm();
          // } else {
          //   this.$message.error(
          //     "Error: Ingresa por lo menos un código para continuar"
          //   );
          //   this.send = false;
          //   this.loading = false;
          // }
        } else {
          this.send = false;
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      me.form.phone = me.form.phone
      me.form.phoneCode = me.phoneCode
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.resetForm("form");
            if (me.serial.confirmation_email) {
              this.$swal.fire({
                icon: "success",
                title: "Registro exitoso!",
                html:
                  "Por favor confirma tu registro desde el email enviado a <strong>" +
                  data.player.email +
                  "</strong> haciendo click en el vínculo, verificar email.<br>" +
                  "<strong style='color:#ec205f'>Por favor verifica también en tu bandeja de no deseados (spam)</strong>",
                showConfirmButton: true,
              });
            } else {
              this.table_bingo = data.table.player.bingo;
              this.$swal
                .fire({
                  icon: "success",
                  title: "Registro exitoso!",
                  allowOutsideClick: false,
                  showConfirmButton: true,
                  showLoaderOnConfirm: true,
                  confirmButtonText:
                    'Descargar Tabla <i class="el-icon-download"></i>',
                  preConfirm: () => {
                    return downloadTable(me.table_bingo)
                      .then(({ data }) => {
                        me.downloadFile(data, "Mis tablas de bingo");
                        return data;
                      })
                      .catch(function (error) {
                        me.$swal.showValidationMessage(
                          `Error: ${error.response.data.message}`
                        );
                      });
                  },
                  // allowOutsideClick: () => !me.$swal.isLoading(),
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    return true;
                  }
                });
            }
          } else {
            if (data.code === 300) {
              this.resetForm("form");
              this.table_bingo = data.table.player.bingo;
              this.$swal
                .fire({
                  icon: "success",
                  title: "Ya estas registrado en nuestro Bingo!",
                  html: "<strong style='color:#ec205f'>Por tu fidelidad, te hemos registrado automaticamente!</strong>",
                  allowOutsideClick: false,
                  showConfirmButton: true,
                  showCancelButton: true,
                  cancelButtonText: "Cerrar",
                  confirmButtonText:
                    'Descargar Tablas <i class="el-icon-download"></i>',
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    me.downloadTable();
                  }
                });
              // setTimeout(() => {
              //   me.getTableBingo(data.player.nit);
              // }, 1000);
            } else {
              if (data.code === 301) {
                this.resetForm("form");
                this.getTableBingo(data.player.nit);
              } else {
                if (data.code === 302) {
                  this.form.email = "";
                  this.$swal
                    .fire({
                      icon: "warning",
                      title: "Atención!",
                      html: data.data + "<br> Por favor ingresa uno diferente",
                      showConfirmButton: true,
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        setTimeout(() => {
                          me.$nextTick(() => me.$refs["email"].focus());
                        }, 400);
                      }
                    });
                } else {
                  if (data.code === 303) {
                    this.resetForm("form");
                    this.table_bingo = data.player.bingo[0].table_bingo;
                    this.$swal
                      .fire({
                        icon: "success",
                        title: "Ya estas registrado!",
                        html:
                          "Tu documento ya está habilitada para jugar el día " +
                          this.gameDateFormat(data.table.player.game_date),
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: "Cerrar",
                        confirmButtonText:
                          'Descargar Tabla <i class="el-icon-download"></i>',
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          me.downloadTable();
                        }
                      });
                    // setTimeout(() => {
                    //   me.getTableBingo(data.player.nit);
                    // }, 1000);
                  } else {
                    if (data.code === 304) {
                      this.$swal
                        .fire({
                          icon: "warning",
                          title: "Atención!",
                          html: data.data,
                          showConfirmButton: true,
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            setTimeout(() => {
                              me.$nextTick(() => me.$refs["nit"].focus());
                            }, 400);
                          }
                        });
                    } else {
                      this.loading = false;
                      this.$message.error("Error! " + data.message);
                    }
                  }
                }
              }
            }
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
          me.loading = false;
          me.$message.error(
            "Error! " + error.response.data.message
          );
          // me.$message.error("Error! " + error.response.data.message);
        });
    },
    tableNumber(value) {
      let me = this;
      this.$prompt(
        "Por favor ingrese su número de documento",
        "Verifica si ya estas registrado!",
        {
          closeOnClickModal: false,
          confirmButtonText: "Consultar",
          cancelButtonText: "Cancelar",
          type: "danger",
          inputType: "text",
          inputValue: value,
          inputPlaceholder: "Número de documento",
          inputErrorMessage: "Ingresa número de documento",
          inputValidator: function (val) {
            if (val === null) {
              return false;
            } else {
              return true;
            }
          },
        }
      ).then(({ value }) => {
        this.getTableBingo(value);
      });
    },
    getTableBingo(value) {
      if (this.hide) {
        var value = this.nit_player;
      }

      if (value != "") {
        this.text_loading = "Cargando Tabla...";
        this.loading_table = true;
        getTable(value)
          .then(({ data }) => {
            this.loading_table = false;
            if (data.player !== false && data.player !== null) {
              if (data.player.confirmed !== 0) {
                setPlayer(JSON.stringify(data.player));
                this.$emit("set", data.player);
                // location.reload();
              } else {
                this.$swal.fire({
                  icon: "warning",
                  title: "La verificación por email no se ha completado!!",
                  html:
                    "Por favor confirma tu registro desde el email enviado a <strong>" +
                    data.player.email +
                    "</strong> haciendo click en el vínculo, verificar email",
                  showConfirmButton: true,
                });
              }
            } else {
              this.$message({
                type: "warning",
                message: "No hay registros con la información ingresada",
              });
            }
          })
          .catch(function (error) {
            this.loading_table = false;
            this.$message.error("Error.");
            console.log(error);
          });
      } else {
        this.$swal
          .fire({
            icon: "warning",
            title: "Atención!!",
            html: "Por favor ingresa en el campo tu número de documento",
            showConfirmButton: true,
          })
          .then((result) => {
            setTimeout(() => {
              this.$nextTick(() => this.$refs.nit_player.focus());
            }, 500);
          });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dynamicTags = [];
      this.city = {};
      this.loading = false;
      this.resetField = true;
      if (this.serial.verify_by_table_code === 1) {
        this.form.verify_by_table_code = ""
        this.showForm = false
      }
    },
    validateAvaliablePlay() {
      getAvailablePlay()
        .then(({ data }) => {
          this.serial = data.serial;
          this.showForm = (data.serial && data.serial.verify_by_table_code === 1) ? false : true;
          this.form.create_table =
            data.serial && data.serial.confirmation_email ? false : true;
          this.loading_game = false;
          if (data.data) {
            this.show = true;
          } else {
            if (data.maintenance) {
              this.maintenance = true;
            } else {
              this.hide = true;
            }
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
        });
    },
    // download Table
    downloadTable() {
      downloadTable(this.table_bingo)
        .then(({ data }) => {
          this.downloadFile(data, "Mis tablas de bingo");
        })
        .catch(function (error) {
          this.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      this.resetField = false;
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
  },
  components: {
    VueRecaptcha,
    // CUSTOM FIELDS
    Company,
    CompanySelect,
    City,
    Country,
    CountrySelect,
    Beneficiary,
    Competitor,
    Zones,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.beneficiary {
  margin-bottom: 0px;
}

.register-page-player {
  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .el-select {
    width: 100%;
  }

  // .form{
  //   .el-form-item{
  //     margin-bottom: 10px !important;
  //   }
  // }
  .loading-game {
    color: #0868ae;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
  }

  .return-play {
    font-size: 15px;
    color: #d43333;
    // color: #da5635;
  }

  .return-maintenance {
    font-size: 20px;
    color: #d43333;
    // color: #da5635;
  }

  .no-play {
    color: #0868ae;
    border: 2px solid #dbdbea;
    border-radius: 30px;
    font-size: 30px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 40px;
    margin-top: 50px;
    width: 400px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }

  .divider {
    margin: 15px 0;
  }

  .styled {
    // margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .accept {
    margin-top: 20px;
  }

  .form-wrapper {
    width: 100%;
  }


  .image-logo {
    width: 350px;
    margin: 0px auto;
    margin-top: 20px;
    display: block;
  }

  .form-box {
    // background: transparent;
    width: 100%;
    max-width: 360px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;
    padding-bottom: 10px;

    a {
      font-size: 14px;
      color: $text-color-accent;
      text-decoration: none;
      font-weight: 500;
    }

    .signin-btn {
      width: 160px;
    }

    .login-box {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    .layout-container .container .view.register-page-player {
      margin-left: -5px;
      margin-right: -5px;
      max-width: calc(100% + 10px);
    }
  }

  @media (max-width: 520px) {
    .no-play {
      width: 250px;
    }
  }

  @media (max-width: 370px) {
    .no-play {
      width: 200px;
    }
  }

  @media (max-width: 300px) {
    .no-play {
      width: 170px;
    }
  }
}
</style>

<style lang="scss">
.quantity_tables {
  float: left;

  .el-input__inner {
    height: 32px !important;
  }
}
</style>

<style lang="css">
.el-icon-circle-check {
  color: #1cc529;
}

.el-autocomplete-suggestion li {
  line-height: 15px !important;
  padding: 5px 20px 5px;
}

.value_item {
  margin: 0;
  height: 15px;
}

.el-autocomplete {
  width: 100% !important;
}

/* placeholder */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #d3d3d3;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d3d3d3;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d3d3d3;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d3d3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d3d3;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #d3d3d3;
}
</style>